import _ from 'lodash'
import React from 'react'
import { render } from 'react-dom'
import { connect, Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import * as navItems from 'mgr/layout/NavItemsConsts'
import Theme from 'mgr/layout/Theme'
import { pickBestShift } from 'mgr/lib/utils/ShiftUtils'
import { store } from 'mgr/pages/reduxStore'
import StatusMessage from 'mgr/pages/single-venue/dayview/containers/StatusMessage'
import { merge } from 'svr/common/SRUtils'
import { VmsIcons, VmsIconSizes } from 'svr/common/VmsIcons'
import Navigation from './Nav'
import { NavigationStyles, NavigationCellStyles } from './Styles'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { SevenRoomsIcon } from '@sevenrooms/react-components/components/SevenRoomsIcon'

const MANAGER2_PREFIX = '/manager2'

export class VenueNavigation extends Navigation {
  constructor(props) {
    super(props)
  }

  render() {
    const { venueUrlKey, venue, venueSettings, date, shiftCategory, activeSection, permissions, user, shifts, hasVenueGroupUsers, state } =
      this.props

    let shift = shiftCategory === 'LEGACY' ? 'NIGHT' : shiftCategory
    if (!shift && shifts.length) {
      shift = pickBestShift(venue, shifts).category
    }
    const getActiveStyle = section => (activeSection === section ? NavigationCellStyles.active : {})
    const dateUrlParam = moment(date).format('MM-DD-YYYY')
    const todayDateUrlParam = moment().format('MM-DD-YYYY')

    const handleSearch = e => {
      e.preventDefault()
      window.universalSearchBootstrap()
    }

    const getMarketingNavigationLink = permissions => {
      if (permissions.isStandaloneMarketing) {
        return `${MANAGER2_PREFIX}/${venueUrlKey}/marketing`
      }
      if (permissions.canViewEvents) {
        return `/manager/${venueUrlKey}/events`
      }
      return `${MANAGER2_PREFIX}/${venueUrlKey}/marketing/venueprofile`
    }

    const showSettingsNav =
      permissions.canAccessSettingsNav ||
      permissions.canAdministrateShiftsAccessRules ||
      permissions.canAdministrateBlackoutDatesDailyProgram

    const navigationHoleLink = permissions.canViewReservations
      ? `/manager/${venueUrlKey}/reservations/day/${todayDateUrlParam}`
      : this.getSettingsRedirect(venueUrlKey, venueSettings, permissions, user.id)

    return (
      <div style={NavigationStyles.body}>
        {permissions.canViewSearch && (
          <a onClick={handleSearch} style={NavigationCellStyles.link} href="hf">
            <div style={merge(VmsIconSizes.small, NavigationStyles.search)}>{VmsIcons.Search}</div>
          </a>
        )}
        <a data-test="contorl-bar-home" href={navigationHoleLink} style={NavigationCellStyles.link}>
          <div style={merge(VmsIconSizes.medium, NavigationStyles.logo)}>
            <SevenRoomsIcon aria-label="Venue Home" fontSize="inherit" />
          </div>
        </a>
        <div
          style={_.extend({}, NavigationStyles.scrollableNavContainer, {
            top: permissions.canViewSearch ? 125 : 45,
          })}
        >
          <div
            className="svr-nav"
            ref={scrollRef => {
              this.scrollRef = scrollRef
            }}
            style={NavigationStyles.scrollableNav}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          >
            <div style={NavigationStyles.navButtons}>
              {this.getNavigationCell(
                permissions.canViewReservations,
                VmsIcons.BookLine,
                VmsIcons.Book,
                activeSection === navItems.RESERVATIONS,
                'Reservations',
                `/manager/${venueUrlKey}/reservations/day/${dateUrlParam}`
              )}
              {this.getNavigationCell(
                permissions.canViewGrid,
                VmsIcons.GridLine,
                VmsIcons.Grid,
                activeSection === navItems.GRID,
                'Grid',
                `${MANAGER2_PREFIX}/${venueUrlKey}/${dateUrlParam}/reservations/grid?shift=${shift}`,
                false,
                false,
                state
              )}
              {this.getNavigationCell(
                permissions.canAccessOperationsTab,
                VmsIcons.FloorplanLine,
                VmsIcons.Floorplan,
                activeSection === navItems.FLOORPLAN,
                'Floorplan',
                `${MANAGER2_PREFIX}/${venueUrlKey}/${dateUrlParam}/reservations/floorplan?shift=${shift}`,
                false,
                false,
                state
              )}
              {this.getNavigationCell(
                permissions.canViewOrders,
                VmsIcons.OrderLine,
                VmsIcons.OrderFill,
                activeSection === navItems.ORDERS,
                'Orders',
                `/manager2/${venueUrlKey}/orders`,
                false,
                false
              )}
              {this.getNavigationCell(
                permissions.canViewGuestlist,
                VmsIcons.GuestlistLine,
                VmsIcons.Guestlist,
                activeSection === navItems.GUESTLIST,
                'Guestlist',
                `/manager/${venueUrlKey}/guestlist/day/${dateUrlParam}`
              )}
              {this.getNavigationCell(
                permissions.canViewRequests,
                VmsIcons.RequestLine,
                VmsIcons.Request,
                activeSection === navItems.REQUESTS,
                'Requests',
                `/manager/${venueUrlKey}/requests/all`
              )}
              {this.getNavigationCell(
                permissions.canViewClients,
                VmsIcons.ClientsLine,
                VmsIcons.Clients,
                activeSection === navItems.CLIENTS,
                'Clients',
                `/manager/${venueUrlKey}/clients`
              )}
              {this.getNavigationCell(
                permissions.canViewInbox,
                VmsIcons.MessageSquareLine,
                VmsIcons.MessageSquare,
                activeSection === navItems.INBOX,
                'Inbox',
                `/app/inbox/${venueUrlKey}`
              )}
              {this.getNavigationCell(
                permissions.canViewMarketing,
                VmsIcons.MarketingLine,
                VmsIcons.Marketing,
                activeSection === navItems.MARKETING,
                'Marketing',
                getMarketingNavigationLink(permissions)
              )}

              {this.getInsightsCell(
                permissions.isRoInsightEnabled &&
                  (permissions.canAccessInsightsPage || (permissions.superheroRoAccess && user.is_superhero)),
                `/manager2/${venueUrlKey}/insights`,
                activeSection === navItems.INSIGHTS,
                state
              )}

              {this.getNavigationCell(
                permissions.canViewReporting,
                VmsIcons.ReportingLine,
                VmsIcons.Reporting,
                activeSection === navItems.REPORTS,
                'Reporting',
                permissions.canViewReportingFull
                  ? `/manager/${venueUrlKey}/reports/view_reports_redirection`
                  : `/manager/${venueUrlKey}/reports/bookedby/${user.id}`
              )}
              {this.getNavigationCell(
                permissions.canAccessPrivateEvents && venueSettings.is_private_events_tab_enabled,
                VmsIcons.Smile,
                null,
                activeSection === navItems.PRIVATE_EVENTS,
                'Private Events',
                `/app/private-events/${venueUrlKey}`
              )}
              {this.getNavigationCell(
                permissions.isLoyaltyProgramEnabled,
                VmsIcons.Programs,
                VmsIcons.Programs,
                activeSection === navItems.PROGRAMS,
                'Programs',
                `/app/loyalty/${venueUrlKey}`,
                undefined,
                undefined,
                undefined,
                undefined,
                true
              )}
              {this.getPortalSwitchCell(
                hasVenueGroupUsers,
                VmsIcons.SwapHoriz,
                null,
                false,
                'Group',
                `/group/switch_portal/venue_group?from_venue_key=${venueUrlKey}`
              )}
            </div>
            <div style={NavigationStyles.profileButtons}>
              <a key="_help" style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.HELP))} href="/help">
                Help
              </a>
              {showSettingsNav ? (
                <a
                  key="_settings"
                  data-test="sr-nav-button-settings"
                  style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.SETTINGS), getActiveStyle(navItems.AVAILABILITY))}
                  href={this.getSettingsRedirect(venueUrlKey, venueSettings, permissions, user.id)}
                >
                  Settings
                </a>
              ) : (
                ''
              )}
              <a
                key="_profile"
                style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.PROFILE))}
                href={`/manager/${venueUrlKey}/account/${user.id}`}
              >
                Profile
              </a>

              <a key="_logout" style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.LOGOUT))} href="/logout">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  state: {
    emailBuilder: state.emailBuilder,
    insights: state.insights,
  },
})

VenueNavigation = connect(mapStateToProps)(VenueNavigation)

export const renderVenueNav = (
  elementId,
  permissions,
  user,
  venueUrlKey,
  date,
  activeSection,
  hasVenueGroupUsers,
  venueSettings,
  shifts = [],
  shiftCategory = null,
  isRouterMode = false
) => {
  render(
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <StatusMessage />
        <VenueNavigation
          {...{
            permissions,
            user,
            venueUrlKey,
            date,
            shifts,
            shiftCategory,
            activeSection,
            hasVenueGroupUsers,
            isRouterMode,
            store,
            venueSettings,
          }}
        />
      </Provider>
    </ThemeProvider>,
    document.getElementById(elementId)
  )
}

svrExport('Navigation', 'renderVenueNav', renderVenueNav)
export default VenueNavigation
